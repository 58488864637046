import logo from './logo.svg';
import './App.css';

function App() {
 return (
    <div className="landing-page ">
      <div className="background-image">
        <div className="content ">
          <h1 style={{fontSize: 72}}>Left Curve Capital</h1>
          <p style={{fontSize: 20, width:'70%', alignSelf: 'center', fontWeight: "bold"}}>
            At Left Curve Capital, we create innovative consumer and software applications and products.
            Our mission is to deliver cutting-edge solutions that enhance user experiences and drive
            business success. Join us on our journey to shape the future of technology.
          </p>
        </div>
      </div>
      <footer>
        <p>Contact us: <a style={{ color: 'white'}} href="mailto:team@leftcurvecapital.co">team@leftcurvecapital.co</a></p>
      </footer>
    </div>)
}

export default App;
